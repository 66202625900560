import request from '@/utils/request'
import { param2Obj } from '@/utils'

export function fetchCartList(data) {
	return request({
		url: '/cart/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCartInfo(data) {
	return request({
		url: '/cart/detail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function createCart(data) {
	return request({
		url: '/cart/add',
		method: 'post',
		data: param2Obj(data)
	})
}

export function updateCart(data) {
	return request({
		url: '/cart/setNum',
		method: 'post',
		data: param2Obj(data)
	})
}

export function deleteCart(data) {
	return request({
		url: '/cart/delete',
		method: 'post',
		data: param2Obj(data)
	})
}

export function totalCart(data) {
	return request({
		url: '/cart/calprice',
		method: 'post',
		data: param2Obj(data)
	})
}
