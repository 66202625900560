<template>
	<div class="mhome">
		<div class="mhome-wrap" v-if="collocationInfo">
			<div class="mhome-swiper">
				<template v-if="collocationInfo.img_urls">
					<swiper ref="mySwiper" :options="swiperOptions">
						<swiper-slide v-for="(image,index) in collocationInfo.img_urls.split(',')" :key="index">
							<img class="swiper-pic" :src="image" @error="goodsLoadError" alt="" width="100%">
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</template>
			</div>
			<div class="collocation-info">
				<div class="title">{{ collocationInfo.name }}</div>
				<div class="desc">{{ collocationInfo.desc }}</div>
				<div class="line-price" v-if="collocationInfo.dis_sel === 1">
					<div class="price" v-if="onSaveMoney > 0">{{ $t('mobile.collocation.mostSave') }} <span>￥<em>{{ onSaveMoney }}</em></span></div>
					<div class="price" v-else>{{ $t('mobile.collocation.price') }} <span>￥<em>{{ onCollocationPrice }}</em></span></div>
					<div class="time">{{ $t('mobile.collocation.activity') }}{{ onRangeTime }}</div>
				</div>
				<div class="line-price" v-else>
					<div class="price">{{ $t('mobile.collocation.price') }} <span>￥<em>{{ onCollocationPrice }}</em></span></div>
				</div>
			</div>
			<div class="collocation-goods">
				<div class="title">{{ $t('mobile.collocation.goods') }}</div>
				<el-checkbox-group v-model="checkGoods" @change="handleChangeGoods">
					<div class="item" v-for="(goods,index) in collocationGoods" :key="index">
						<el-checkbox :label="goods.goods_id+'_'+goods.spec_id" :disabled="goods.is_main === 1 || collocationInfo.type === 2 || parseInt(goods.stock) < 1">{{ }}</el-checkbox>
						<div class="item-main">
							<div class="pic"><img :src="goods.default_image" @error="goodsLoadError" alt=""></div>
							<div class="item-box">
								<div class="num">x{{ goods.num }}</div>
								<div class="name">{{ goods.goods_name }}</div>
								<div class="price" v-if="collocationInfo.type === 1">
									￥{{ onGoodsPrice(goods) }}
									<span v-if="onGoodsDisPrice(goods) > 0"><em>{{ $t('mobile.collocation.saved') }}</em>￥{{ onGoodsDisPrice(goods) }}</span>
								</div>
								<div class="price" v-else>￥{{ goods.origin_price }}</div>
								<div class="spec" v-if="goods.goods_spec.length > 0" @click="handlePopShow(goods,index)">
									<span>{{ onGoodsSpec(goods) }}</span>
									<i class="el-icon-arrow-right"></i>
								</div>
							</div>
						</div>
					</div>
				</el-checkbox-group>
			</div>
			<div class="mpop" :class="{'mpop-show':isPopShow}">
				<div class="mpop-bg" @click="handlePopClose"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose"><i class="el-icon-close"></i></div>
					<div class="mpop-item">
						<div class="item-pic"><img :src="specsItem.img_url?specsItem.img_url:goodsInfo.default_image" @error="goodsLoadError" alt=""></div>
						<div class="item-main">
							<div class="item-price">
								<div class="price">￥<span>{{ onPopPrice }}</span></div>
							</div>
						</div>
					</div>
					<div class="mpop-main">
						<div class="mpop-specs">
							<div class="item" v-for="item in Object.keys(specsList)" :key="item">
								<div class="item-label">{{item}}</div>
								<div class="item-block">
									<el-radio-group v-model="checkSpecs[item]" size="small" @change="handleSpecsChange">
										<el-radio-button v-for="specs in specsList[item]" :key="specs" :label="specs">
										</el-radio-button>
									</el-radio-group>
								</div>
							</div>
							<div class="item item-number">
								<div class="item-label">
									{{$t('mobile.goods.text21')}}<span>({{$t('mobile.goods.text22')}}{{specsItem.stock}}{{$t('mobile.goods.text23')}})</span>
								</div>
								<div class="item-block">
									<div class="minput-number">
										<div class="number-decrease is-disabled"><i class="el-icon-minus"></i></div>
										<input class="number-inner" type="text" disabled="disabled" v-model="goodsInfo.num">
										<div class="number-increase is-disabled"><i class="el-icon-plus"></i></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleGoodsSpec">{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
			<div class="collocation-list" v-if="collocationList.length > 0">
				<div class="title">{{$t('mobile.collocation.other')}}</div>
				<swiper ref="collocationSwiper" :options="swiperCollocation">
					<swiper-slide v-for="(item,index) in collocationList" :key="index">
						<router-link :to="{ name: 'mGoodsCollocation', params: { id: item.collocation_treasure_id}}" class="collocation-item">
							<div class="pic"><img :src="item.img_urls.split(',')[0]" alt=""></div>
							<div class="item-main">
								<div class="name">{{ item.name}}</div>
								<div class="text"></div>
								<div class="time">{{$t('mobile.goods.activityTo')}}{{ item.end_time | parseTime('{y}-{m}-{d}')}}</div>
							</div>
						</router-link>
					</swiper-slide>
				</swiper>
			</div>
			<div class="mhome-block"></div>
			<div class="mhome-bottom">
				<div class="mfooter-nav">
					<div class="footer-main">
						<div class="price-bd"><span class="text">{{$t('mobile.collocation.price')}}</span><span class="price">￥<em>{{ onTotalPrice }}</em></span></div>
						<div class="price-has" v-if="onTotalDisPrice">{{$t('mobile.collocation.saved')}}<em>￥{{ onTotalDisPrice }}</em></div>
					</div>
					<div class="footer-box">
						<ul>
							<li>
								<el-button type="add-cart" :disabled="true" @click="handleSubmit('cart')">{{$t('mobile.actions.addCart')}}</el-button>
							</li>
							<li>
								<el-button type="buy-now" :disabled="true" @click="handleSubmit('buy')">{{$t('mobile.actions.buyNow')}}</el-button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { fetchCollocationInfo } from "@/api/buyer/goods"
import { createCart } from '@/api/buyer/cart'
export default {
	computed: {
		onSaveMoney() {
			var price = 0
			if(this.collocationGoods.length > 0) {
				this.collocationGoods.forEach(item => {
					if(item.goods_spec && item.goods_spec.length > 0) {
						var maxDis = 0
						item.goods_spec.forEach(child => {
							if(parseFloat(child.dis_price) > 0) {
								var dis = parseFloat(child.origin_price) - parseFloat(child.dis_price)
								if(dis > maxDis) {
									maxDis = dis
								}
							}
						})
						price += maxDis*item.num
					} else {
						if(parseFloat(item.dis_price) > 0) {
							var dis = (parseFloat(item.origin_price) - parseFloat(item.dis_price))*item.num
							price += dis
						}
					}
				})
			}
			return price.toFixed(2)
		},
		onRangeTime() {
			let text = '还剩'
			var curTime = parseInt(Date.now()/1000)
			if(this.collocationInfo.end_time > curTime) {
				var longTime = parseInt(this.collocationInfo.end_time - curTime)
				var dd = parseInt(longTime / 86400)
				var longH = longTime % 86400
				var hh = parseInt(longH / 3600)
				var longM = longH % 3600
				var mm = parseInt(longM / 60)
				var ss = longM % 60
				if(dd > 0) {
					text += dd + '天'
				} else {
					if(hh > 0) {
						text += hh + '时'
					}
					if(mm > 0) {
						text += mm + '分'
					}
					if(ss > 0) {
						text += ss + '秒'
					}
				}
			} else {
				text = '已结束'
			}
			return text
		},
		onCollocationPrice() {
			var minPrice = 0
			var maxPrice = 0
			if(this.collocationGoods.length > 0) {
				this.collocationGoods.forEach(item => {
					if(item.goods_spec && item.goods_spec.length > 0) {
						var min = 0
						var max = 0
						item.goods_spec.forEach(child => {
							var s_price = child.dis_price || child.origin_price
							if(!min > 0 || min > parseFloat(s_price)) {
								min = parseFloat(s_price)
							}
							if(!max > 0 || max < parseFloat(s_price)) {
								max = parseFloat(s_price)
							}
						})
						if(item.is_main === 1) {
							minPrice = min*item.num
						}
						maxPrice+=max*item.num
					} else {
						var price = item.dis_price || item.origin_price
						if(item.is_main === 1) {
							minPrice=price*item.num
						}
						maxPrice+=price*item.num
					}
				})
			}
			if(minPrice !== maxPrice) {
				return minPrice.toFixed(2)+'-'+maxPrice.toFixed(2)
			} else {
				return minPrice.toFixed(2)
			}
		},
		onPopPrice() {
			var text = ''
			if(this.specsItem.goods_spec && this.specsItem.goods_spec.length >0) {
				var min = 0
				var max = 0
				this.specsItem.goods_spec.forEach(item => {
					var g_price = item.dis_price || item.origin_price
					if(!min > 0 || min > parseFloat(g_price)) {
						min = parseFloat(g_price)
					}
					if(!max > 0 || max < parseFloat(g_price)) {
						max = parseFloat(g_price)
					}
				})
				text = min.toFixed(2)+'-'+max.toFixed(2)
			} else if(this.specsItem.spec_id > 0) {
				var s_price = this.specsItem.dis_price || this.specsItem.origin_price
				text = parseFloat(s_price).toFixed(2)
			}
			return text
		},
		onTotalPrice() {
			var minPrice=0
			var maxPrice=0
			if(this.collocationGoods.length > 0) {
				this.collocationGoods.forEach(item => {
					if(this.checkGoods.includes(item.goods_id+'_'+item.spec_id)) {
						if(item.goods_spec && item.goods_spec.length > 0) {
							var min = 0
							var max = 0
							item.goods_spec.forEach(child => {
								var spec_price = child.dis_price || child.origin_price
								if(parseInt(item.spec_id) > 0) {
									if(parseInt(child.spec_id) === parseInt(item.spec_id)) {
										min = parseFloat(spec_price)
										max = parseFloat(spec_price)
									}
								} else {
									if(!min > 0 || min > parseFloat(spec_price)) {
										min = parseFloat(spec_price)
									}
									if(!max > 0 || max < parseFloat(spec_price)) {
										max = parseFloat(spec_price)
									}
								}
							})
							if(min!==max) {
								minPrice+=min*item.num
								maxPrice+=max*item.num
							} else {
								minPrice+=parseFloat(min)*item.num
								maxPrice+=parseFloat(min)*item.num
							}
						} else {
							var price = item.dis_price || item.origin_price
							minPrice+=parseFloat(price)*item.num
							maxPrice+=parseFloat(price)*item.num
						}
					}
				})
			}
			if(minPrice!==maxPrice) {
				return minPrice.toFixed(2)+'-'+maxPrice.toFixed(2)
			} else {
				return minPrice.toFixed(2)
			}
		},
		onTotalDisPrice() {
			var min_dis = 0
			var max_dis = 0
			if(this.collocationGoods.length > 0) {
				this.collocationGoods.forEach(item => {
					if(this.checkGoods.includes(item.goods_id+'_'+item.spec_id)) {
						if(item.goods_spec && item.goods_spec.length > 0) {
							var min = 0
							var max = 0
							item.goods_spec.forEach(child => {
								if(child.dis_price*1>0) {
									var spec_dis = parseFloat(child.origin_price) - parseFloat(child.dis_price)
									if(parseInt(item.spec_id) > 0) {
										if(parseInt(child.spec_id) === parseInt(item.spec_id)) {
											min = parseFloat(spec_dis)
											max = parseFloat(spec_dis)
										}
									} else {
										if(!min > 0 || min > parseFloat(spec_dis)) {
											min = parseFloat(spec_dis)
										}
										if(!max > 0 || max < parseFloat(spec_dis)) {
											max = parseFloat(spec_dis)
										}
									}
								}
							})
							if(min !== max) {
								min_dis += min*item.num
								max_dis += max*item.num
							} else {
								min_dis += min*item.num
								max_dis += min*item.num
							}
						} else {
							if(item.dis_price * 1 > 0) {
								var num = parseFloat(item.origin_price) - parseFloat(item.dis_price)
								if(num > 0) {
									min_dis += num*item.num
									max_dis += num*item.num
								}
							}
						}
					}
				})
			}
			var text = ''
			if(min_dis !== max_dis) {
				text = min_dis.toFixed(2) + '-' + max_dis.toFixed(2)
			} else if(min_dis > 0) {
				text = min_dis.toFixed(2)
			}
			return text
		}
	},
	data() {
		return {
			swiperOptions: {
				loop: false,
				autoplay: true,
				pagination: {
					el: '.swiper-pagination',
				},
			},
			swiperCollocation: {
				loop: false
			},
			collocationInfo: {},
			collocationGoods: [],
			collocationList: [],
			checkGoods: [],
			isPopShow: false,
			popIndex: -1,
			goodsInfo: {},
			specsList: {},
			specsItem: {},
			checkSpecs: {},
			quantity: 0,
		}
	},
	created () {
		this.getData()
	},
	methods: {
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		getData() {
			fetchCollocationInfo({collocation_treasure_id: this.$route.params.id}).then(response => {
				const { data } = response
				this.collocationInfo = data
				this.collocationGoods = data.list_goods || []
				this.collocationList = data.collocation_treasure_info || []
				var check_ids = []
				this.collocationGoods.forEach(item => {
					if(data.type === 1) {
						if(item.is_main === 1) {
							check_ids.push(item.goods_id+'_'+item.spec_id)
						}
					} else {
						check_ids.push(item.goods_id+'_'+item.spec_id)
					}
				})
				this.checkGoods = check_ids
			})
		},
		onGoodsPrice(goods) {
			var text = ''
			if(goods.goods_spec && goods.goods_spec.length > 0) {
				var min = 0
				var max = 0
				goods.goods_spec.forEach(item => {
					var spec_price = item.dis_price || item.origin_price
					if(parseInt(goods.spec_id) > 0 && parseInt(goods.spec_id) === parseInt(item.spec_id)) {
						min = parseFloat(spec_price)
						max = parseFloat(spec_price)
					} else if(!parseInt(goods.spec_id) > 0) {
						if(!min > 0 || min > parseFloat(spec_price)) {
							min = parseFloat(spec_price)
						}
						if(!max > 0 || max < parseFloat(spec_price)) {
							max = parseFloat(spec_price)
						}
					}
				})
				if(min!==max) {
					text = min.toFixed(2)+'-'+max.toFixed(2)
				} else {
					text = min.toFixed(2)
				}
			} else {
				var price = goods.dis_price || goods.origin_price
				text = parseFloat(price).toFixed(2)
			}
			return text
		},
		onGoodsDisPrice(goods) {
			var text = ''
			if(goods.goods_spec && goods.goods_spec.length > 0) {
				var min = 0
				var max = 0
				goods.goods_spec.forEach(item => {
					var spec_num = parseFloat(item.origin_price) - parseFloat(item.dis_price)
					if(item.dis_price*1>0) {
						if(goods.spec_id > 0 && goods.spec_id === item.spec_id) {
							min = spec_num
							max = spec_num
						} else if(!goods.spec_id > 0) {
							if(!min>0 || min>spec_num) {
								min = spec_num
							}
							if(!max>0 || max<spec_num) {
								max = spec_num
							}
						}
					}
				})
				if(min!==max) {
					text = min.toFixed(2)+'~'+max.toFixed(2)
				} else {
					text = min.toFixed(2)
				}
			} else {
				if(goods.dis_price*1>0) {
					var num = parseFloat(goods.origin_price) - parseFloat(goods.dis_price)
					text = num.toFixed(2)
				}
			}
			return text
		},
		onGoodsSpec(goods) {
			var text = '请选择主要颜色 尺码'
			if(parseInt(goods.spec_id) > 0) {
				goods.goods_spec.forEach(item => {
					if(parseInt(goods.spec_id) === parseInt(item.spec_id)) {
						text = '颜色:'+item.spec_1+' 尺码:'+item.spec_2
					}
				})
			}
			return text
		},
		handleChangeGoods(val) {
		},
		handlePopShow(goods,index) {
			this.goodsInfo = Object.assign({}, goods)
			var curKey = goods.goods_id+'_'+goods.spec_id
			if(!this.checkGoods.includes(curKey)) {
				this.checkGoods.push(curKey)
			}
			this.checkSpecs = {}
			this.popIndex = index
			this.getSpecsList(goods.goods_spec)
			this.isPopShow = true
		},
		getSpecsList(data = []) {
			if (data.length > 0) {
				let specArr1 = [];
				let specArr2 = [];
				data.forEach(item => {
					if (!specArr1.includes(item.spec_1) && item.spec_1) {
						specArr1.push(item.spec_1);
					}
					if (!specArr2.includes(item.spec_2) && item.spec_2) {
						specArr2.push(item.spec_2);
					}
					if(this.goodsInfo.spec_id > 0 && this.goodsInfo.spec_id===item.spec_id) {
						this.checkSpecs['颜色'] = item.spec_1
						this.checkSpecs['尺码'] = item.spec_2
						this.specsItem = JSON.parse(JSON.stringify(item))
					}
				})
				if (specArr1.length > 0) {
					this.specsList['颜色'] = specArr1;
				}
				if (specArr2.length > 0) {
					this.specsList['尺码'] = specArr2;
				}
				if(!parseInt(this.specsItem.spec_id) > 0) {
					this.specsItem = JSON.parse(JSON.stringify(this.goodsInfo))
				}
			} else {
				this.specsList = {};
				this.checkSpecs = {};
			}
		},
		handlePopClose() {
			this.isPopShow = false
		},
		handleSpecsChange(val) {
			let specs = JSON.parse(JSON.stringify(this.goodsInfo.goods_spec))
			specs.forEach(item => {
				if (item.spec_1 === this.checkSpecs['颜色'] && item.spec_2 === this.checkSpecs['尺码']) {
					this.specsItem = item
				}
			})
		},
		handleGoodsSpec() {
			if(!this.specsItem.spec_id > 0) {
				this.$message.error('请选择商品属性!')
				return false
			}
			var oldKey = this.goodsInfo.goods_id+'_'+this.goodsInfo.spec_id
			var newKey = this.goodsInfo.goods_id+'_'+this.specsItem.spec_id
			this.checkGoods.splice(this.checkGoods.indexOf(oldKey),1,newKey)
			this.$set(this.goodsInfo,'spec_id',this.specsItem.spec_id)
			this.collocationGoods[this.popIndex] = JSON.parse(JSON.stringify(this.goodsInfo))
			this.isPopShow = false
			this.$forceUpdate()
		},
		handleSubmit(type) {
			if(this.checkGoods.length < 2) {
				this.$message({
					message: '请至少选择一个搭配商品',
					customClass: 'wap-message',
					type: 'error'
				})
				return false
			}
			var spec_flag = false
			this.collocationGoods.forEach(item => {
				if(this.checkGoods.includes(item.goods_id+'_'+item.spec_id)) {
					if(item.goods_spec && item.goods_spec.length > 0) {
						if(!item.spec_id > 0) {
							spec_flag = true
						}
					}
				}
			})
			if(spec_flag) {
				this.$message({
					message: '还有商品属性没选择哦',
					customClass: 'wap-message',
					type: 'error'
				});
				return false
			}
			if(type === 'cart') {
				this.onAddCart()
			} else {
				this.onAddBuy()
			}
		},
		onAddCart() {
			const hasToken = getToken();
			if (!hasToken) {
				this.$message({
					message: this.$t('mobile.errorMsg.login'),
					customClass: 'wap-message',
					type: 'warning',
					duration: 1500,
					onClose: () => {
						window.location.href = this.$loginBasePath + '/m/?jump_url=' + window.location.href;
					}
				});
				return false
			}
			var goods_items = []
			this.checkGoods.forEach(item => {
				var value = item.split('_')
				goods_items.push({goods_id: value[0],spec_id: value[1]})
			})
			var params = {
				from: 'activity',
				activity_id: this.collocationInfo.collocation_treasure_id,
				store_id: this.collocationInfo.store_id,
				goods_items: goods_items
			}
			createCart(params).then(() => {
				this.isPopShow = false;
				this.$message({
					message: this.$t('mobile.successMsg.addCart'),
					customClass: 'wap-message',
					type: 'success'
				});
			})
		},
		onAddBuy() {
			var goods_items = this.checkGoods.join(',')
			let queryStr = "from=activity" + '&store_id=' + this.collocationInfo.store_id+ '&activity_id=' + this.$route.params.id + '&goods_items=' + goods_items
			window.location.href = this.$buyBasePath + "/m/order/confirm_order?" + queryStr
		},
	},
	watch: {
		'$route': {
			handler: function (val, oldVal) {
				this.getData()
			},
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>
.mhome-swiper {
	height: 12rem;
	position: relative;
	background-color: #FFFFFF;

	::v-deep .swiper-container {
		height: 100%;
	}

	::v-deep .swiper-pagination {
		height: 0;
		bottom: 0.704rem;

		.swiper-pagination-bullet {
			width: 0.256rem;
			height: 0.256rem;
			background-color: #FFFFFF;
			opacity: .5;
		}

		.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}

	.swiper-pic {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
.mhome-wrap {
	.collocation-info {
		background-color: #FFFFFF;
		padding: 0.576rem 0.384rem;
		font-size: 0.384rem;
		.title {
			color: #333;
			font-size: 0.576rem;
			line-height: 0.672rem;
			margin-bottom: 0.256rem;
		}
		.desc {
			color: #828282;
			font-size: 0.448rem;
			margin-bottom: 0.288rem;
		}
		.line-price {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.price {
				color: #f50;
				font-size: 0.384rem;
				span {
					font-size: 0.512rem;
					em {
						font-size: 0.64rem;
					}
				}
			}
			.time {
				color: #828282;
			}
		}
	}
	.collocation-goods {
		margin: 0.32rem 0;
		background-color: #FFFFFF;
		.title {
			padding: 0 0.32rem;
			font-size: 0.448rem;
			height: 1.152rem;
			line-height: 1.152rem;
		}
		.item {
			display: flex;
			align-items: center;
			::v-deep .el-checkbox {
				width: 1.408rem;
				text-align: center;
				.el-checkbox__label {
					display: none;
				}
			}
			.pic {
				flex-shrink: 0;
				width: 2.816rem;
				height: 2.816rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.item-main {
				flex: 1;
				width: 0;
				display: flex;
				padding: 0.384rem 0.384rem 0.384rem 0;
				border-bottom: 1px solid #eee;
			}
			.item-box {
				flex: 1;
				width: 0;
				margin-left: 0.384rem;
				font-size: 0.384rem;
				.num {
					float: right;
				}
				.name {
					height: 1.088rem;
					width: 5.376rem;
					line-height: 1.4;
					display: -webkit-box;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin-bottom: 0.192rem;
				}
				.price {
					color: #f50;
					font-size: 0.448rem;
					line-height: 0.576rem;
					span {
						font-size: 0.384rem;
						margin-left: 0.144rem;
						em {
							color: #828282;
						}
					}
				}
				.spec {
					color: #828282;
					padding-top: 0.352rem;
					line-height: 1.2;
					display: flex;
					justify-content: space-between;
				}
			}
			&:last-child {
				.item-main {
					border-bottom: none;
				}
			}
		}
	}
	.collocation-list {
		margin-bottom: 0.32rem;
		overflow: hidden;
		background-color: #FFFFFF;
		.title {
			padding: 0 0.32rem;
			font-size: 0.448rem;
			height: 1.152rem;
			line-height: 1.152rem;
		}
		.collocation-item {
			margin: 0 0.32rem;
			padding: 0.16rem 0.32rem 0.16rem 0.096rem;
			background-color: #fafafa;
			display: flex;
			.pic {
				flex-shrink: 0;
				width: 3.84rem;
				height: 3.84rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.item-main {
				flex: 1;
				width: 0;
				display: flex;
				align-items: left;
				justify-content: center;
				flex-direction: column;
				line-height: 0.704rem;
				margin-left: 0.384rem;
				.name {
					color: #333333;
					font-size: 0.384rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.text {
					color: #f40;
					font-size: 0.384rem;
				}
				.time {
					font-size: 0.352rem;
					color: #999999;
				}
			}
		}
	}
}

.mhome-block {
	height: 1.472rem;
}

.mfooter-nav {
	display: flex;
	justify-content: space-between;
	background-color: #FFFFFF;
	box-shadow: 0 -1px 1px #E5E5E5;

	.footer-main {
		max-width: 4.4rem;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 0.128rem 0 0 0.384rem;
		.price-bd {
			line-height: 0.64rem;
			white-space: nowrap;
			.text {
				font-size: 0.384rem;
				color: #333;
			}
			.price {
				color: #f50;
				font-size: 0.48rem;
				em {
					font-size: 0.512rem;
				}
			}
		}
		.price-has {
			font-size: 0.352rem;
			color: #999999;
			em {
				color: #f50;
			}
		}
	}

	.footer-box {
		padding: 0.16rem 0.384rem;

		ul {
			overflow: hidden;
			border-radius: 0.576rem;

			li {
				float: left;
				width: 3.2rem;
				height: 1.152rem;
			}
		}

		::v-deep .el-button {
			padding: 0;
			height: 1.152rem;
			line-height: 1.152rem;
			width: 3.2rem;
			text-align: center;
			font-size: 0.416rem;
			display: inline-block;
			vertical-align: top;
			border: none;
			border-radius: 0;
			color: #FFFFFF;

			&.el-button--add-cart {
				background: linear-gradient(to right, #f3cc40, #f6993c);
			}

			&.el-button--buy-now {
				background: linear-gradient(to right, #ee7e32, #f25528);
			}

			&.is-disabled {
				color: rgba(255, 255, 255, .5);
			}
		}
	}
}

.mpop-item {
	padding: 0.384rem;

	.item-pic {
		float: left;
		width: 1.92rem;
		height: 1.92rem;

		img {
			width: 100%;
			height: 100%;
			border-radius: 0.128rem;
			object-fit: cover;
		}
	}

	.item-main {
		min-height: 1.92rem;
		margin-left: 2.24rem;
		padding-right: 1.28rem;

		.name {
			height: 0.768rem;
			line-height: 0.768rem;
			font-size: 0.448rem;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.item-price {
			.price {
				// margin-top: 0.128rem;
				color: #FF5000;
				font-size: 0.384rem;
				height: 0.768rem;
				line-height: 0.768rem;
				display: inline-block;
				vertical-align: middle;

				span {
					font-size: 0.576rem;
					font-weight: bold;
				}
			}

			.seckill-price {
				color: #FFFFFF;
				font-size: 0.352rem;
				padding: 0 0.48rem;
				display: inline-block;
				vertical-align: middle;
				border-radius: 0.448rem;
				height: 0.768rem;
				line-height: 0.768rem;
				margin-left: 0.32rem;
				background: linear-gradient(to right, #f25528, #d8243a);

				span {
					font-size: 0.48rem;
				}
			}
		}
	}
}

.mpop-main {
	max-height: 8.96rem;
	overflow-y: auto;
}

.mpop-specs {
	padding: 0 0.384rem;
	max-width: 12.288rem;
	box-sizing: border-box;

	.item {
		padding: 0.256rem 0;
		border-bottom: 1px solid #E5E5E5;

		.item-label {
			padding: 0.192rem 0;
			font-size: 0.448rem;
			line-height: 0.64rem;

			span {
				color: #999999;
			}
		}

		.item-block {
			::v-deep .el-radio-group {
				margin-top: -0.256rem;

				.el-radio-button {
					margin-right: 0.256rem;
					margin-top: 0.256rem;

					.el-radio-button__inner {
						font-size: 0.416rem;
						line-height: 1em;
						padding: 0.272rem 0.32rem;
						border-radius: 0.128rem;
						border-left: 1px solid #DCDFE6;
					}

					.el-radio-button__orig-radio:checked+.el-radio-button__inner {
						color: #f25528;
						border-color: #f25528;
						background-color: transparent;
						box-shadow: none;
					}
				}
			}

			.minput-number {
				height: 1.024rem;
				line-height: 1.024rem;
				box-sizing: border-box;
				border: 1px solid #DCDFE6;
				border-radius: 0.128rem;
				overflow: hidden;

				.number-decrease,
				.number-increase {
					color: #606266;
					text-align: center;
					font-size: 0.416rem;
					height: 100%;
					width: 1.024rem;
					display: inline-block;
					vertical-align: top;
					background-color: #F5F7FA;
				}

				.number-decrease {
					border-right: 1px solid #DCDFE6;
				}

				.number-increase {
					border-left: 1px solid #DCDFE6;
				}

				.number-decrease.is-disabled,
				.number-increase.is-disabled {
					color: #c0c4cc;
				}

				.number-inner {
					height: 100%;
					width: 2.08rem;
					display: inline-block;
					vertical-align: top;
					text-align: center;
					font-size: 0.416rem;
					padding: 0 0.224rem;
					box-sizing: border-box;
				}
			}
		}
	}

	.item:last-child {
		border-bottom: none;
	}

	.item-number {
		display: flex;
		justify-content: space-between;
	}
}
</style>