import request from '@/utils/request'
import { param2Obj } from '@/utils'

export function fetchGoodsInfo(data) {
	return request({
		url: '/goods/detail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchGoodsComments(data) {
	return request({
		url: '/goods/comments',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchGoodsConsults(data) {
	return request({
		url: '/goods/consults',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchGoodsRecommends(data) {
	return request({
		url: '/goods/recommends',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCollocationInfo(data) {
	return request({
		url: '/goods/collocation/detail',
		method: 'post',
		data: param2Obj(data)
	})
}